<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data') }}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'freezer-pending-list'}"
        >
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list') }}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other') }}
      </div>
    </b-alert>

    <template v-if="elementData">
      <element-edit-tab
        :element-data="elementData"
        :contact-type-list="contactTypeList"
        :department-list="departmentList"
        :city-list="cityList"
        :relationship-list="relationshipList"
        :population-zone-list="populationZoneList"
        :delivery-city-list="deliveryCityList"
        class="mt-2 pt-75"
      />
    </template>

  </div>
</template>

<script>
import {
  BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import elementStoreModule from '../storeModule'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab,
  },
  data() {
    return {
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-freezer-pending-edit',
      RESOURCES_ELEMENT_NAME: 'freezer',
      newElement: true,
      populationZoneList: [],
      contactTypeList: [],
      departmentList: [],
      cityList: [],
      deliveryCityList: [],
      relationshipList: [],
      elementData: undefined,
    }
  },
  async mounted() {
    const _self = this
    this.$root.$on('fetch-cities', async departmentId => {
      console.log('fetching', departmentId)
      _self.cityList = await _self.fetch_key_value('geography', 3, departmentId)
    })
    this.$root.$on('fetch-delivery-cities', async departmentId => {
      console.log('fetching', departmentId)
      _self.deliveryCityList = await _self.fetch_key_value('geography', 3, departmentId)
    })
    // Register module
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, elementStoreModule)

    this.populationZoneList = await this.fetch_key_value('population_zone')
    this.departmentList = await this.fetch_key_value('geography', 2)
    this.contactTypeList = await this.fetch_key_value('contact_type')
    this.relationshipList = await this.fetch_key_value('relationship')
    this.elementData = await this.fetchElement()
  },
  beforeDestroy() {
    this.$root.$off('fetch-cities')
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_key_value(type, deep, parent) {
      return store.dispatch(`${this.ARL_CODES_APP_STORE_MODULE_NAME}/fetch_key_value`, { type, deep, parent }).then(response => {
        const list = response.data.items
        list.unshift({ id: -1, key: this.$t('list.defaultOption'), value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return []
        }
      })
    },
    async fetchElement() {
      this.newElement = false
      return store.dispatch(`${this.ARL_CODES_APP_STORE_MODULE_NAME}/fetch_element`, { id: router.currentRoute.params.id })
        .then(response => response.data)
        .catch(error => undefined)
    },
    save(elementData) {
      const method = `${this.ARL_CODES_APP_STORE_MODULE_NAME}/save_element`
      this.newElement = false
      const _self = this
      store
        .dispatch(method, { data: elementData })
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.success`),
            },
          })
          _self.$router.replace({ name: 'freezer-pending-list' })
        })
        .catch(error => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.update.failure`),
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
