var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('patient-edit-card',{attrs:{"element-data":_vm.elementData.case.patient,"contact-type-list":_vm.contactTypeList,"department-list":_vm.departmentList,"city-list":_vm.cityList,"relationship-list":_vm.relationshipList,"population-zone-list":_vm.populationZoneList}}),_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Datos de entrega")])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label-for":"address"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.address')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","maxlength":"100"},model:{value:(_vm.elementData.address),callback:function ($$v) {_vm.$set(_vm.elementData, "address", $$v)},expression:"elementData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{attrs:{"id":"geography"}},[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"department"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.department')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.department'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.departmentList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onDeliveryDepartmentChange},model:{value:(_vm.elementData.address_department_id),callback:function ($$v) {_vm.$set(_vm.elementData, "address_department_id", $$v)},expression:"elementData.address_department_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"city"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.city')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.deliveryCityList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.onDeliveryCityChange},model:{value:(_vm.elementData.address_city_id),callback:function ($$v) {_vm.$set(_vm.elementData, "address_city_id", $$v)},expression:"elementData.address_city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"town"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.town')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.town'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"town","maxlength":"100"},model:{value:(_vm.elementData.address_town),callback:function ($$v) {_vm.$set(_vm.elementData, "address_town", $$v)},expression:"elementData.address_town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"population_zone"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.population_zone')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.population_zone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.populationZoneList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.address_population_zone_id),callback:function ($$v) {_vm.$set(_vm.elementData, "address_population_zone_id", $$v)},expression:"elementData.address_population_zone_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"city_code"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.city_code')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.city_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.elementData.address_city)?_c('b-form-input',{attrs:{"id":"city_code","disabled":""},model:{value:(_vm.elementData.address_city.key),callback:function ($$v) {_vm.$set(_vm.elementData.address_city, "key", $$v)},expression:"elementData.address_city.key"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"observations"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold info_title"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.observations')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.observations')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"observations","maxlength":"250"},model:{value:(_vm.elementData.observations),callback:function ($$v) {_vm.$set(_vm.elementData, "observations", $$v)},expression:"elementData.observations"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){_vm.router().go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }